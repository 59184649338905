import { EnvironmentUrls } from 'Roblox';
import UrlConfig from '../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/http/interfaces/UrlConfig';
import { PAYMENT_PROVIDER } from './paymentConstants';

const { apiGatewayUrl, premiumFeaturesApi } = EnvironmentUrls;

const url = {
  getSubscriptions: {
    url: `${apiGatewayUrl}/v1/subscriptions/active-subscription-products`,
    withCredentials: true
  },
  getSubscriptionStatuses: {
    url: `${apiGatewayUrl}/v1/subscriptions/statuses`,
    withCredentials: true
  },
  getSubscriptionMetadata: {
    url: `${apiGatewayUrl}/v1/subscriptions/metadata`,
    withCredentials: true
  },
  purchaseSubscription: (targetKey: string): UrlConfig => ({
    url: `${apiGatewayUrl}/v1/subscriptions/prepare-purchase/${targetKey}/web`,
    withCredentials: true
  }),
  getUserPremiumSubscription: (userId: number): UrlConfig => ({
    url: `${premiumFeaturesApi}/v1/users/${userId}/subscriptions/details`,
    withCredentials: true
  }),
  getUserSubscriptions: {
    url: `${apiGatewayUrl}/v1/subscriptions/user`,
    withCredentials: true
  },
  cancelSubscription: (subscriptionProductTargetKey: string): UrlConfig => ({
    url: `${apiGatewayUrl}/v1/subscriptions/${subscriptionProductTargetKey}/cancel`,
    withCredentials: true
  }),
  cancelPremiumSubscription: (userId: number): UrlConfig => ({
    url: `${premiumFeaturesApi}/v1/users/${userId}/subscriptions/cancel`,
    withCredentials: true
  }),
  // Payments related endpoints
  verifyPaymentProfileCreation: (providerPaymentProfileId: string): UrlConfig => ({
    url: `${apiGatewayUrl}/payments-gateway/v1/payment-profile/by-provider-id/${PAYMENT_PROVIDER.Stripe}/${providerPaymentProfileId}`,
    withCredentials: true
  }),
  // Safety related endpoints
  submitSafetyEvent: {
    url: `${apiGatewayUrl}/abuse-reporting/v1/safety-event`,
    retryable: true,
    withCredentials: true
  },
  // Roblox Credit related endpoints
  getUserCreditBalance: (): UrlConfig => ({
    url: `${apiGatewayUrl}/credit-balance/v1/get-credit-balance`,
    withCredentials: true
  })
};

export default {
  url
};
