import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-utilities';
import {
  Thumbnail2d,
  ThumbnailTypes,
  ThumbnailFormat,
  DefaultThumbnailSize
} from 'roblox-thumbnails';
import { Button, IconButton, Popover } from 'react-style-guide';
import { CurrentUser } from 'Roblox';
import { Subscription } from '../../../core/types/serviceTypes';
import { FeatureSubscriptions } from '../../../core/constants/translationConstants';
import SubscriptionModal from './SubscriptionModal';
import '../../../../css/gameSubscriptions/subscriptionCard.scss';
import useGameSubscriptions from '../hooks/useGameSubscriptions';
import trackerClient, {
  SubscriptionInputType,
  SubscriptionPurchaseEventType,
  SubscriptionViewName
} from '../utils/logging';
import { submitSafetyEvent } from '../../../core/services/safetyServices';
import useSystemFeedbackContext from '../../shared/hooks/useSystemFeedback';

type TSubscriptionCardProps = {
  subscription: Subscription;
  primaryPaymentProviderType?: string;
  allPaymentProviderTypes?: string[];
};

const SubscriptionCard = ({
  subscription,
  primaryPaymentProviderType = 'Stripe',
  allPaymentProviderTypes = []
}: TSubscriptionCardProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const { systemFeedbackService } = useSystemFeedbackContext();
  const { translate } = useTranslation();
  const { state } = useGameSubscriptions();
  const { purchaseFlowUuid } = state;

  const {
    name,
    subscriptionProviderName,
    description,
    displayPrice,
    iconImageAssetId,
    subscriptionTargetKey,
    isForSale
  } = subscription;

  const thumbnailComponent = (
    <Thumbnail2d
      type={ThumbnailTypes.assetThumbnail}
      size={DefaultThumbnailSize}
      targetId={iconImageAssetId}
      altName={name}
      imgClassName='subscription-thumbnail'
      containerClass='subscription-thumbnail-container-class'
    />
  );

  return (
    <Fragment>
      <div className='subscription-card-item subscription-fc'>
        <div className='subscription-fc'>
          <div className='subscription-options-container'>
            <Popover
              id='subscriptions-dropdown-menu'
              button={
                // Note: onClick is not an optional parameter for an IconButton but is not needed for the popover
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <IconButton iconName='more' size={IconButton.sizes.small} onClick={() => {}} />
              }
              containerPadding={20}
              trigger='click'
              placement='bottom'>
              <ul className='dropdown-menu' role='menu'>
                <li>
                  <Button
                    className='rbx-subscription-reports'
                    variant={Button.variants.secondary}
                    onClick={() =>
                      submitSafetyEvent({
                        subscriptionTargetKey,
                        imageAssetId: iconImageAssetId.toString(),
                        reporterId: CurrentUser.userId
                      })
                        .then(() =>
                          systemFeedbackService.success(
                            translate(FeatureSubscriptions.MessageReportSubmitted)
                          )
                        )
                        .catch(() =>
                          systemFeedbackService.warning(
                            translate(FeatureSubscriptions.ErrorGenericError)
                          )
                        )
                    }>
                    {translate(FeatureSubscriptions.ActionReportSubscription)}
                  </Button>
                </li>
              </ul>
            </Popover>
          </div>
          <div className='subscription-fr'>
            <div className='subscription-thumbnail-container'>{thumbnailComponent}</div>
            <div className='subscription-information'>
              <span className='font-header-2 subscription-name'>{name}</span>
              <div className='subscription-description-container'>
                <span className='font-body subscription-description'>{description}</span>
              </div>
              <button
                className='font-header-2 subscription-see-more'
                type='button'
                onClick={() => {
                  trackerClient.sendExperienceSubscriptionEvent(
                    purchaseFlowUuid,
                    SubscriptionPurchaseEventType.USER_INPUT,
                    SubscriptionViewName.DEVSUB_CARD,
                    subscription,
                    SubscriptionInputType.OPEN_PURCHASE_MODAL
                  );
                  setShowModal(true);
                }}>
                {translate(FeatureSubscriptions.ActionSeeMore) || 'See More'}
              </button>
            </div>
          </div>
        </div>
        <Button
          width={Button.widths.full}
          isDisabled={!isForSale}
          onClick={() => {
            trackerClient.sendExperienceSubscriptionEvent(
              purchaseFlowUuid,
              SubscriptionPurchaseEventType.USER_INPUT,
              SubscriptionViewName.DEVSUB_CARD,
              subscription,
              SubscriptionInputType.OPEN_PURCHASE_MODAL
            );
            setShowModal(true);
          }}
          className='subscription-buy-button subscription-fr'>
          {isForSale ? (
            <Fragment>
              <p className='subscription-price'>{displayPrice}</p>
              <p className='subscription-cadence'>
                {translate(FeatureSubscriptions.LabelPerMonth)}
              </p>
            </Fragment>
          ) : (
            <div className='text-disabled'>{translate(FeatureSubscriptions.LabelSubscribed)}</div>
          )}
        </Button>
      </div>
      <SubscriptionModal
        title={translate(FeatureSubscriptions.HeadingGetSubscription)}
        show={showModal}
        assetId={iconImageAssetId}
        provider={subscriptionProviderName}
        name={name}
        displayPrice={displayPrice}
        description={description}
        isForSale={isForSale}
        cadence={translate(FeatureSubscriptions.LabelPerMonth)}
        cadenceDisclaimer={translate(FeatureSubscriptions.MessageMonthlyCadenceDisclaimer)}
        closeModal={closeModal}
        primaryPaymentProviderType={primaryPaymentProviderType}
        allPaymentProviderTypes={allPaymentProviderTypes}
        subscription={subscription}
      />
    </Fragment>
  );
};

SubscriptionCard.defaultProps = {
  primaryPaymentProviderType: 'Stripe',
  allPaymentProviderTypes: []
};

export default SubscriptionCard;
