import { createContext } from 'react';
import { uuidService } from 'core-utilities';
import { deviceMeta } from 'header-scripts';
import { Subscription } from '../../../core/types/serviceTypes';
import Action, { GameSubscriptionActionTypes } from './GameSubscriptionActions';
import trackerClient, { SubscriptionPurchaseEventType, SubscriptionViewName } from './logging';

const getPathName = (): string => {
  const pathName = window.location.pathname.substring(1);
  if (pathName.startsWith('games/store-section')) {
    return pathName;
  }

  return `${pathName}#!/store`;
};

export interface GameSubscriptionsState {
  subscriptions: Subscription[];
  subscriptionsPerPage: number;
  subscriptionsInPage: Subscription[];
  currentPage: number;
  totalPages: number;
  // Used for instrumentation to not repeat the same page
  pagesSeen: Set<number>;
  showPagination: boolean;
  stripeClientSecret: string;
  showStripeModal: boolean;
  purchaseFlowUuid: string;
  selectedSubscription: Subscription | null;
  pathName: string;
}

export const initialState: GameSubscriptionsState = {
  subscriptions: [],
  subscriptionsPerPage: deviceMeta.getDeviceMeta()?.isUniversalApp ? 8 : 4,
  subscriptionsInPage: [],
  currentPage: 1,
  totalPages: 0,
  pagesSeen: new Set(),
  showPagination: false,
  stripeClientSecret: '',
  showStripeModal: false,
  purchaseFlowUuid: uuidService.generateRandomUuid(),
  selectedSubscription: null,
  pathName: getPathName()
};

function sendImpressionEventForPage(state: GameSubscriptionsState) {
  state.subscriptionsInPage.forEach(currentSub => {
    trackerClient.sendExperienceSubscriptionEvent(
      state.purchaseFlowUuid,
      SubscriptionPurchaseEventType.IMPRESSION,
      SubscriptionViewName.GAME_DETAILS,
      currentSub
    );
  });
}

function getSubscriptionsByPage(state: GameSubscriptionsState, pageNumber: number): Subscription[] {
  const offset = (pageNumber - 1) * state.subscriptionsPerPage;
  const newSubscriptions = state.subscriptions.slice(
    offset,
    Math.min(pageNumber * state.subscriptionsPerPage, state.subscriptions.length)
  );

  return newSubscriptions;
}

export function gameSubscriptionsReducer(
  state: GameSubscriptionsState,
  action: Action
): GameSubscriptionsState {
  switch (action.type) {
    case GameSubscriptionActionTypes.UPDATE_PAGE: {
      const newState = {
        ...state,
        subscriptionsInPage: getSubscriptionsByPage(state, action.newPage),
        currentPage: action.newPage
      };

      if (!state.pagesSeen.has(action.newPage)) {
        sendImpressionEventForPage(newState);
      }

      return {
        ...newState,
        pagesSeen: state.pagesSeen.add(action.newPage)
      };
    }
    case GameSubscriptionActionTypes.LOAD_SUBSCRIPTIONS: {
      const totalCalculatedPages = Math.ceil(
        action.subscriptions.length / state.subscriptionsPerPage
      );

      const newState = {
        ...state,
        totalPages: totalCalculatedPages,
        subscriptions: action.subscriptions,
        showPagination: totalCalculatedPages > 1
      };

      return {
        ...newState,
        subscriptionsInPage: getSubscriptionsByPage(newState, 1)
      };
    }
    case GameSubscriptionActionTypes.OPEN_STRIPE_MODAL:
      return {
        ...state,
        showStripeModal: true,
        stripeClientSecret: action.clientSecret,
        selectedSubscription: action.subscription
      };
    case GameSubscriptionActionTypes.CLOSE_STRIPE_MODAL:
      return {
        ...state,
        showStripeModal: false
      };
    case GameSubscriptionActionTypes.SEND_STORE_PAGE_LOAD_EVENT: {
      if (!state.pagesSeen.has(state.currentPage)) {
        sendImpressionEventForPage(state);
        return {
          ...state,
          pagesSeen: state.pagesSeen.add(state.currentPage)
        };
      }

      return state;
    }
    default:
      return state;
  }
}

export interface GameSubscriptionsContextValues {
  state: GameSubscriptionsState;
  dispatch: React.Dispatch<Action>;
}

const gameSubscriptionsContext = createContext<GameSubscriptionsContextValues | null>(null);

export default gameSubscriptionsContext;
