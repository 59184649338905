import { AxiosResponse, httpService } from 'core-utilities';
import serviceConstants from '../constants/serviceConstants';

export const verifyPaymentProfileCreationPoll = async (): Promise<boolean> => {
  return Promise.resolve(true);
};

export const verifyPaymentProfileCreation = async (
  providerPaymentProfileId: string
): Promise<AxiosResponse> => {
  const response = await httpService.get(
    serviceConstants.url.verifyPaymentProfileCreation(providerPaymentProfileId)
  );

  return response;
};

export default {
  verifyPaymentProfileCreationPoll
};
