import React, { useEffect, Fragment, useState } from 'react';
import { deviceMeta } from 'header-scripts';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-utilities';
import PaginationBase from '../../../../../../Roblox.CoreUI.WebApp/Roblox.CoreUI.WebApp/js/react/pagination/components/Pagination';
import { FeatureSubscriptions } from '../../../core/constants/translationConstants';
import { SubscriptionProductType } from '../../../core/types/subscriptionEnums';
import metadataConstants from '../../../core/constants/metadataConstants';
import {
  getSubscriptionMetadata,
  getSubscriptions,
  getSubscriptionsStatuses
} from '../../../core/services/subscriptionServices';
import SubscriptionsList from '../components/SubscriptionsList';
import StripeContainer from './StripeContainer';
import useSystemFeedbackContext from '../../shared/hooks/useSystemFeedback';
import useGameSubscriptions from '../hooks/useGameSubscriptions';
import { GameSubscriptionActionTypes } from '../utils/GameSubscriptionActions';

export const SubscriptionsContainer = (): JSX.Element => {
  const { universeId = '' } = metadataConstants.gameMetadataData() || {};
  const { SystemFeedback } = useSystemFeedbackContext();
  const { translate } = useTranslation();
  const { state, dispatch } = useGameSubscriptions();
  const { showPagination, subscriptionsInPage, totalPages, currentPage } = state;
  const [isStorePage, setIsStorePage] = useState(false);
  const [primaryPaymentProvider, setPrimaryPaymentProvider] = useState('');
  const [allPaymentProviders, setAllPaymentProviders] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const [isWebPurchasingEnabled, setIsWebPurchasingEnabled] = useState(false);

  const logError = () => {
    // TODO: Add error instrumentation here.
    // https://roblox.atlassian.net/browse/SUBS-2115
  };

  useEffect(() => {
    if (
      location?.hash === '#!/store' ||
      deviceMeta.getDeviceMeta()?.isUniversalApp ||
      deviceMeta.getDeviceMeta()?.isPhone ||
      deviceMeta.getDeviceMeta()?.isTablet
    ) {
      setIsStorePage(true);
      dispatch({ type: GameSubscriptionActionTypes.SEND_STORE_PAGE_LOAD_EVENT });
    }
  }, [dispatch, location, location?.hash, setIsStorePage]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let computedUniverseId = '0';

      if (universeId) {
        computedUniverseId = universeId;
      } else if (deviceMeta.getDeviceMeta()?.isUniversalApp) {
        const [, , , urlUniverseId] = location.pathname.split('/');
        if (urlUniverseId) {
          computedUniverseId = urlUniverseId;
        }
      }

      const {
        subscriptionProductsInfo,
        primaryPaymentProviderType,
        allPaymentProviderTypes
      } = await getSubscriptions({
        subscriptionProductType: SubscriptionProductType.DEVELOPER_SUBSCRIPTION_PRODUCT,
        subscriptionProviderId: parseInt(computedUniverseId, 10) ?? 0
      });

      if (subscriptionProductsInfo.length > 0) {
        const targetKeys = subscriptionProductsInfo.map(info => info.subscriptionTargetKey);

        const { subscriptionStatuses } = await getSubscriptionsStatuses({
          subscriptionProductTargetKeys: targetKeys
        });

        subscriptionProductsInfo.forEach((info, index) => {
          if (subscriptionStatuses[info.subscriptionTargetKey] !== null) {
            const status = subscriptionStatuses[info.subscriptionTargetKey];
            subscriptionProductsInfo[index].isForSale = !status.isSubscribed;
          }
        });
      }

      dispatch({
        type: GameSubscriptionActionTypes.LOAD_SUBSCRIPTIONS,
        subscriptions: subscriptionProductsInfo
      });
      setPrimaryPaymentProvider(primaryPaymentProviderType);
      setAllPaymentProviders(allPaymentProviderTypes);
      setIsLoading(false);
    };

    const fetchMetadata = async (): Promise<boolean> => {
      const response = await getSubscriptionMetadata();
      return response.isWebPurchasingEnabled;
    };

    if (isStorePage) {
      fetchMetadata()
        .then(purchasingEnabled => {
          setIsWebPurchasingEnabled(purchasingEnabled);

          if (purchasingEnabled) {
            fetchData().catch(logError);
          }
        })
        .catch(logError);
    }
  }, [dispatch, isStorePage, location.pathname, universeId]);

  if (!isWebPurchasingEnabled) {
    return <Fragment />;
  }

  if (isLoading || subscriptionsInPage.length === 0) {
    return <Fragment />;
  }

  return (
    <div id='rbx-subscriptions-container-content'>
      <div className='container-header'>
        <h3>{translate(FeatureSubscriptions.HeadingSubscriptions)}</h3>
      </div>
      <Fragment>
        <SubscriptionsList
          subscriptions={subscriptionsInPage}
          primaryPaymentProviderType={primaryPaymentProvider}
          allPaymentProviderTypes={allPaymentProviders}
        />
        {showPagination && (
          <PaginationBase
            current={currentPage}
            hasNext={currentPage < totalPages}
            onChange={(newPage: number) =>
              dispatch({ type: GameSubscriptionActionTypes.UPDATE_PAGE, newPage })
            }
            total={totalPages}
          />
        )}
        <StripeContainer />
        <SystemFeedback />
      </Fragment>
    </div>
  );
};

export default SubscriptionsContainer;
