import { EnvironmentUrls } from 'Roblox';

export const ANDROID_CANCEL_RENEWAL_URL = 'https://play.google.com/store/account/subscriptions';

export const VPC_URL = `${EnvironmentUrls.websiteUrl}/parents/account-setup?consentType=SavePaymentMethods`;

export const CREDIT_CHECKOUT_URL = `${EnvironmentUrls.websiteUrl}/upgrades/redeem?subscriptionTargetKey=`;

export const buildCreditCheckoutUrl = (subscriptionTargetKey: string): string =>
  `${CREDIT_CHECKOUT_URL}${subscriptionTargetKey}`;

export const CHECKOUT_SUCCESS_URL = `${EnvironmentUrls.websiteUrl}/upgrades/checkout/success`;

export const APP_STORE_CANCEL_HELP_URL = 'https://help.roblox.com/hc/articles/360029312472';
